import { parseContent } from '../lib/parser.js';
import { CoreComponent } from './component.js';
import { appState, settingsState } from './state.js';

export class Post extends CoreComponent {
    static is = 'post';

    static observedAttributes = ['entryid'];

    static template = this.html`
        <feed-post>
            <div id="supported-enclosures" class="thumbnail" slot="media"></div>

            <h1 class="heading" slot="heading"></h1>

            <feed-source slot="source">
                <a class="source keep-fresh" href="#"></a>
                <div class="author"></div>
            </feed-source>

            <feed-date class="date" slot="date"></feed-date>

            <a class="category-link" href="#" slot="category">
                <feed-category class="category-text"></feed-category>
            </a>

            <app-actions slot="actions">
                <icon-button class="star" icon="mdi:star"></icon-button>
                <icon-button class="read" icon="mdi:eye-outline"></icon-button>
                <icon-button class="download" icon="mdi:download" title="Download original"></icon-button>
                <tts-button class="tts"></tts-button>
                <a class="external" href="#" target="_blank">
                    <icon-button icon="mdi:open-in-new"></icon-button>
                </a>
            </app-actions>

            <div id="content"></div>

            <details id="attachments" hidden>
                <summary>Attachments</summary>
                <div id="enclosures"></div>
            </details>
        </feed-post>
    `;

    firstRendered() {
        this.contentEl = this.$('#content');
        this.downloadEl = this.$('.download')
        this.supportedEnclosuresEl = this.$('#supported-enclosures');
        this.enclosuresEl = this.$('#enclosures');

        this.$('.star').addEventListener('click', this.toggleBookmark);
        this.$('.read').addEventListener('click', this.toggleReadStatus);
        this.downloadEl.addEventListener('click', this.downloadOriginalContent);

        this.fetchEntry();

        this.unsubscribeTTSVoice = settingsState.ttsVoice.subscribe(
            voice => this.$('.tts').voice = voice
        );
    }

    disconnectedCallback() {
        super.disconnectedCallback();

        this.unsubscribeTTSVoice();

        this.$('.tts').stopReading();
    }

    fetchEntry() {
        appState.fetchEntry(this.entryId)
            .then(data => {
                if (data) this.data = data;
            })
            .then(() => {
                if (settingsState.autoMarkEntriesAsRead.value) this.autoMarkAsRead();
            });
    }

    toggleBookmark = () => {
        appState.toggleBookmark(this.entryId)
            .then(() => this.starred = !this.starred);
    };

    toggleReadStatus = () => {
        const status = this.read ? 'unread' : 'read';
        appState.toggleReadStatus(+this.entryId, status)
            .then(() => this.read = status);
    };

    downloadOriginalContent = () => {
        if (this.#originalContent === null) {
            this.downloadEl.icon = 'mdi:loading';
            this.downloadEl.spinIcon = true;

            appState.fetchEntryOriginalContent(this.entryId)
                .then(response => {
                    this.#originalContent = parseContent(response?.content || '');
                    this.originalContentShown = true;
                    this.updatedContent(this.originalContent);
                })
                .finally(() => {
                    this.downloadEl.spinIcon = false;
                });

        } else {
            const content = this.originalContentShown
                ? this.content
                : this.originalContent;
            this.originalContentShown = !this.originalContentShown;
            this.updatedContent(content);
        }
    };

    autoMarkAsRead() {
        if (!this.read) this.toggleReadStatus();
    }

    updateData() {
        this.$('.heading').innerText = this.title;

        this.$('.category-link').href = `#/categories/${this.categoryId}`;
        this.$('.category-text').innerText = this.categoryTitle;

        this.$('.source').href = `#/feeds/${this.feedId}`;
        this.$('.source').innerText = this.feedTitle;
        this.$('.author').innerText = this.author;

        this.$('.date').timestamp = this.publishedAt;

        this.updateStarred();
        this.updateRead();

        const url = settingsState.rewriteUrl(this.url);

        this.$('.external').href = url;
        this.$('.external').title = this.title;

        this.updatedContent(this.content);

        this.updateEnclosures(url);
    }

    updateStarred() {
        this.$('.star').icon = this.starred ? 'mdi:star' : 'mdi:star-outline';
        this.$('.star').title = this.starred ? 'Unstar' : 'Star';
    }

    updateRead() {
        this.$('.read').icon = this.read ? 'mdi:eye-outline' : 'mdi:check';
        this.$('.read').title = this.read ? 'Unread' : 'Read';
    }

    updatedContent(content=[]) {
        this.contentEl.replaceChildren(...content);
        this.$('.tts').paragraphs = [...this.contentEl.children].map(
            el => el.textContent
        );
    }

    updateEnclosures(url='') {
        this.supportedEnclosuresEl.replaceChildren();
        this.enclosuresEl.replaceChildren();

        this.$('#attachments').hidden = true;
        this.$('#attachments').open = false;

        if (url.includes('youtube.com')) {
            this.createYoutubeEnclosure(url);
            return;
        }

        for (const enclosure of this.enclosures) {
            const [ type, _ ] = enclosure.mime_type.split('/');

            switch (type) {
                case 'image':
                    this.createImageEnclosure(enclosure);
                    break;

                case 'video':
                    this.createVideoEnclosure(enclosure);
                    break;

                case 'audio':
                    this.createAudioEnclosure(enclosure);
                    break;

                default:
                    this.createGenericEnclosure(enclosure);
                    break;
            }
        }
    }

    createYoutubeEnclosure(url) {
        const el = document.createElement('iframe');

        const youtubeEmbedURL = settingsState.youtubeEmbed.value;
        const videoID = new URL(url).searchParams.get('v');

        el.src = `https://${youtubeEmbedURL}/embed/${videoID}`;

        el.allowFullscreen = true;

        this.addAttachment(el);
    }

    createImageEnclosure(enclosure) {
        const el = document.createElement('img');

        el.src = enclosure.url;

        this.addAttachment(el);
    }

    createVideoEnclosure(enclosure) {
        const el = document.createElement('video');

        el.src = enclosure.url;
        el.type = enclosure.media_type;
        el.controls = true;

        this.addAttachment(el);
    }

    createAudioEnclosure(enclosure) {
        const el = document.createElement('audio');

        el.src = enclosure.url;
        el.type = enclosure.media_type;
        el.controls = true;

        this.addAttachment(el);
    }

    createGenericEnclosure(enclosure) {
        const el = document.createElement('a');

        el.href = enclosure.url;
        el.target = '_blank';
        el.innerText = enclosure.url;

        this.$('#attachments').hidden = false;
        this.enclosuresEl.appendChild(el);
    }

    addAttachment(el) {
        const headerMediaIsEmpty = !this.supportedEnclosuresEl.children.length;

        if (headerMediaIsEmpty) {
            this.supportedEnclosuresEl.appendChild(el);

        } else {
            this.$('#attachments').hidden = false;
            this.enclosuresEl.appendChild(el);
        }
    }

    #data = {};
    #content = null;
    #originalContent = null;
    #originalContentShown = false;

    get data() {
        return this.#data;
    }

    set data(value) {
        this.#data = value;
        this.#content = parseContent(value?.content || '');
        this.updateData();
    }

    get entryId() {
        return this.getAttribute('entryid');
    }

    set entryId(value) {
        this.setAttribute('entryid', value);
    }

    get title() {
        return this.#data.title;
    }

    get url() {
        return this.#data.url;
    }

    get starred() {
        return this.#data.starred;
    }

    set starred(value) {
        this.#data.starred = value;
        this.updateStarred();
    }

    get status() {
        return this.#data.status;
    }

    get read() {
        return this.status === 'read';
    }

    set read(value) {
        this.#data.status = value;
        this.updateRead();
        this.fireEvent('entry-read-status-changed', {
            feedId: this.feedId,
            categoryId: this.categoryId,
            read: this.read,
        });
    }

    get author() {
        return this.#data.author;
    }

    get publishedAt() {
        return this.#data.published_at;
    }

    get feedId() {
        return this.#data.feed.id;
    }

    get feedTitle() {
        return this.#data.feed.title;
    }

    get categoryId() {
        return this.#data.feed.category.id;
    }

    get categoryTitle() {
        return this.#data.feed.category.title;
    }

    get content() {
        return this.#content?.cloneNode(true).childNodes || [];
    }

    get originalContent() {
        return this.#originalContent?.cloneNode(true).childNodes || [];
    }

    get originalContentShown() {
        return this.#originalContentShown;
    }

    set originalContentShown(value) {
        this.#originalContentShown = value;
        this.downloadEl.icon = value ? 'mdi:text-box' : 'mdi:text-box-outline';
    }

    get enclosures() {
        return this.#data.enclosures || [];
    }

    attributeChangedCallback(_attr, oldValue, newValue) {
        if (newValue === oldValue) return;

        if (this.isConnected) this.fetchEntry();
    }
}

Post.define();