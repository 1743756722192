import { CoreComponent } from './component.js';
import { accountState, appState, listState, settingsState, viewState } from './state.js';

export class List extends CoreComponent {
    static is = 'list';

    static template = this.html`
        <feed-list>
            <app-header slot="filters" compact>
                <h2 id="header" slot="title"></h2>
                <app-actions wrap>
                    <toggle-button id="mark-as-read-button">
                        <icon-button slot="open" title="Search" icon="mdi:done-all"></icon-button>
                        <icon-button slot="close" title="More actions" icon="mdi:close"></icon-button>
                        <icon-button id="mark-all-as-read" icon="mdi:done-all" with-label>Mark all as read</icon-button>
                        <icon-button id="mark-unstarred-as-read" icon="mdi:star-check-outline" with-label>Mark unstarred as read</icon-button>
                    </toggle-button>

                    <flux-refresh-feed-button hidden class="feed-refresh" preventmultiplerefresh></flux-refresh-feed-button>

                    <a class="external" href="#" target="_blank">
                        <icon-button icon="mdi:open-in-new" with-label>Feed source</icon-button>
                    </a>

                </app-actions>
            </app-header>

            <div id="main" class="grid"></div>

            <icon-button id="load-more" slot="pagination" icon="mdi:arrow-down" with-label>Load More</icon-button>
        </feed-list>
    `;

    #page = 0;

    firstRendered() {
        this.headerEl = this.$('#header');
        this.mainEl = this.$('#main');
        this.loadMoreEl = this.$('#load-more');
        this.markAllAsReadEl = this.$('#mark-all-as-read');
        this.markUnstarredAsReadEl = this.$('#mark-unstarred-as-read');

        this.addEventListener('statuschanged', (e) => {
            if (e.detail.status === 'success') {
                this.fetchFreshEntries(true);
                this.$('.feed-refresh').setIdleState();
            }
        });

        this.loadMoreEl.addEventListener('click', () => this.fetchEntries(this.#page));
        this.markAllAsReadEl.addEventListener('click', () => this.markAllAsRead({ skipStarred: false }));
        this.markUnstarredAsReadEl.addEventListener('click', () => this.markAllAsRead({ skipStarred: true }));

        this.activeTokenUbsubscribe = accountState.activeToken.subscribe(
            token => {
                this.fetchFreshEntries(!!token);
                this.loadMoreEl.hidden = !token;
            }
        );

        this.currentViewTitleUnsubscribe = viewState.currentViewTitle.subscribe(
            () => this.fetchFreshEntries(!!accountState.activeToken.value)
        );

        this.currentViewFeedIdUnsubscribe = viewState.currentFeedId.subscribe(
            feedId => {
                this.$('.feed-refresh').setIdleState();
                this.$('.feed-refresh').hidden = !feedId;
                this.$('.feed-refresh').feedid = feedId;
            }
        );

        this.currentViewSourceUrlUnsubscribe = viewState.currentViewSourceUrl.subscribe(
            url => {
                this.$('.external').hidden = !url;
                this.$('.external').href = url;
            }
        );

        this.filtersUnsubscribe = listState.filters.subscribe(
            () => this.fetchFreshEntries(!!accountState.activeToken.value)
        );
    }

    disconnectedCallback() {
        this.activeTokenUbsubscribe();
        this.currentViewTitleUnsubscribe();
        this.currentViewFeedIdUnsubscribe();
        this.currentViewSourceUrlUnsubscribe();
        this.filtersUnsubscribe();
    }

    fetchFreshEntries = (fetchData=false) => {
        this.#page = 0;

        this.headerEl.innerText = '';
        this.mainEl.replaceChildren();

        if (fetchData) this.fetchEntries();
    };

    #fetchInProgress = false;

    fetchEntries() {
        this.loadMoreEl.hidden = false;
        this.loadMoreEl.icon = 'mdi:loading';
        this.loadMoreEl.spinIcon = true;

        if (this.#fetchInProgress) return;
        this.#fetchInProgress = true;

        appState.fetchEntries(this.#page)
            .then(({ total=0, entries=[] }) => {
                this.headerEl.innerText = `Found ${total} entries`;

                if (entries.length < accountState.auth.entries_per_page)
                    this.loadMoreEl.hidden = true;

                for (const entry of entries) {
                    const entryIdExists = this.$(`flux-entry[entryid="${entry.id}"]`);
                    const entryHashExists = this.$(`flux-entry[hash="${entry.hash}"]`);

                    // Skip duplicates in pagination
                    if (entryIdExists) continue;

                    const entryEl = document.createElement('flux-entry');

                    entryEl.setAttribute('entryid', entry.id);
                    entryEl.setAttribute('hash', entry.hash);
                    entryEl.data = entry;

                    this.mainEl.appendChild(entryEl);

                    // Mark duplicates in database as error
                    if (settingsState.markDuplicateEntries.value && (!entryIdExists && entryHashExists)) {
                        entryEl.classList.add('error');
                        if (!entryEl.read) entryEl.toggleReadStatus();
                    }
                }

                this.#page++;
            })
            .finally(() => {
                this.loadMoreEl.icon = 'mdi:arrow-down';
                this.loadMoreEl.spinIcon = false;
                this.#fetchInProgress = false;
            });
    }

    async markAllAsRead({ skipStarred=false }) {
        const entryEls = this.mainEl.querySelectorAll(`flux-entry:not([read]${skipStarred ? ', [starred]' : ''})`);

        for (const el of entryEls) {
            await el.toggleReadStatus();
        }

        this.$('#mark-as-read-button').open = false;
    }
}

List.define();