import { WebComponent, css, html } from '../lib/web-component.js';
import { size } from './tokens.js';

const slottedFormStyles = css`
    ::slotted(form),
    ::slotted(input),
    ::slotted(select) {
        margin: 0;
        min-inline-size: 0;
        display: flex;
        align-items: center;
        gap: 1rem;
    }
`;

export class AppHeader extends WebComponent {
    static is = 'app-header';

    static styles = [
        slottedFormStyles,
        css`
            :host {
                display: grid;
                grid-template-columns: 1fr auto;
                gap: 0 1rem;
                align-items: center;
                min-block-size: ${size.header};
            }

            :host([compact]) {
                display: flex;
                flex-flow: row wrap;
                justify-content: space-between;
                min-block-size: auto;
            }

            .Title,
            .Secondary {
                min-inline-size: 0;
                display: flex;
                flex-flow: column;
                gap: .5rem;
                justify-content: center;
                align-items: center;
                padding-block: 1rem;
            }

            :host([compact]) .Secondary {
            }

            ::slotted(*) {
                inline-size: 100%;
                margin: 0;
            }

            ::slotted(h2) {
                font-size: 2.5rem;
                font-weight: normal;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        `,
    ];

    static template = html`
        <div class="Title">
            <slot name="title"></slot>
        </div>
        <div class="Secondary">
            <slot></slot>
        </div>
    `;
}

AppHeader.define();


export class AppActions extends WebComponent {
    static is = 'app-actions';

    static styles = [
        slottedFormStyles,
        css`
            :host {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 1rem;
            }

            :host([wrap]) {
                flex-wrap: wrap;
            }
        `,
    ];
}

AppActions.define();

