import { css } from '../lib/web-component.js';
import { appTheme, config, mediaQueries, surface } from './tokens.js';

export const global = css`
    *, *::before, *::after {
        box-sizing: border-box;
    }

    [hidden] {
        display: none !important;
    }

    .brand {
        --font-1: ${appTheme.brand};
        --font-2: ${appTheme.brand};

        color: var(--font-1);
    }

    .error {
        --font-1: ${appTheme.error};
        --font-2: ${appTheme.error};

        color: var(--font-1);
    }

    a.keep-fresh {
        --visited: ${appTheme.link};
    }

    a:has(menu-item, icon-button) {
        color: inherit;
        text-decoration: none;
    }

    feed-post .thumbnail > img,
    feed-post .thumbnail > video,
    feed-post .thumbnail > audio,
    feed-card .thumbnail > img,
    feed-card .thumbnail > video,
    feed-card .thumbnail > audio {
        inline-size: 100%;
    }

    [disabled],
    :host([disabled]) {
        color: ${appTheme.font2};
        opacity: .7;
        pointer-events: none;
    }
`;

const documentStyles = css`
    html {
        font-size: ${config.baseUnit};
    }

    body {
        ${surface.surface1}
        font-family: system-ui, sans-serif;
        font-size: ${config.baseFontSize};
        line-height: 1.5;
        margin: 0;
        scrollbar-color: ${appTheme.tinted1} ${appTheme.surface4};
    }
`;

const themeProps = (palette) => `
    --brand: ${palette.brand};

    --surface-1: ${palette.surface1};
    --surface-2: ${palette.surface2};
    --surface-3: ${palette.surface3};
    --surface-4: ${palette.surface4};

    --tinted-1: ${palette.tinted1};
    --tinted-2: ${palette.tinted2};

    --font-1: ${palette.font1};
    --font-2: ${palette.font2};

    --link: ${palette.link};
    --visited: ${palette.visited};

    --error: ${palette.error};
`;

const theme = css`
    :root {
        ${themeProps(config.theme.light)}
    }

    @media ${mediaQueries.darkMode} {
        :root {
            ${themeProps(config.theme.dark)}
        }
    }
`;

const colorSchemes = css`
    ${Object.entries(config.theme).map(
        ([name, palette]) => `
            [color-scheme="${name}"] {
                ${themeProps(palette)}
            }
        `
    ).join('')}
`;

export const postStyles = css`
    a {
        color: ${appTheme.link};
        transition: filter .2s;
    }

    a:visited {
        color: ${appTheme.visited};
    }

    a:hover,
    a:focus {
        filter: brightness(1.2);
    }

    a:active {
        filter: brightness(1.5);
    }

    img {
        max-inline-size: 100%;
        block-size: auto;
        border-radius: 2rem;
    }

    feed-post img {
        margin: 2rem auto;
    }

    iframe, video, audio {
        inline-size: 100%;
        max-inline-size: 100%;
        margin: 2rem auto;
    }

    .thumbnail iframe {
        border: none;
        aspect-ratio: 16 / 9;
        border-radius: 2rem;
    }

    figure {
        display: grid;
        place-items: center;
        margin-inline: 2rem;
    }

    /* Duplicate image fix for Envato feeds */
    figure img[srcset$="2x"]:not(:first-child) {
        display: none;
    }

    pre {
        overflow-x: auto;
    }
`;

export const inputStyles = css`
    body {
        accent-color: ${appTheme.brand};
    }

    label {
        font-size: 2rem;
    }

    label > span,
    label > div {
        display: flex;
        align-items: center;
        gap: 1rem;
        margin-block: 1rem;
    }

    label .push {
        margin-inline-start: auto;
    }

    input,
    select {
        ${surface.tinted2}
        display: block;
        border: none;
        padding: 1rem;
        border-radius: 1rem;
        font-size: 2rem;
        inline-size: 100%;
        outline: none;
        transition: background .1s, filter .1s;
    }

    input[type="checkbox"],
    input[type="radio"] {
        display: inline-block;
        inline-size: auto;
    }

    input:hover,
    input:focus,
    select:hover,
    select:focus {
        ${surface.tinted1}
    }

    input::placeholder {
        color: ${appTheme.font2};
    }

    input:not(:placeholder-shown):not(:focus):invalid {
        color: ${appTheme.error};
        box-shadow: 0 0 0 .2rem ${appTheme.error};
    }

    optgroup {
        ${surface.surface3}
    }

    option {
        ${surface.surface4}
    }

    form {
        margin: 0;
        display: flex;
        flex-flow: column;
        gap: 1rem;
    }

    form .footer {
        display: flex;
        flex-flow: row-reverse wrap;
        align-items: center;
        justify-content: flex-start;
        gap: 1rem;
    }
`;

export const commonStyles = [
    global,
    postStyles,
    inputStyles,
];

document.adoptedStyleSheets = [
    documentStyles,
    theme,
    colorSchemes,
    ...commonStyles,
];