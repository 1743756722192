import { parseContent } from '../lib/parser.js';
import { CoreComponent } from './component.js';
import { appState, settingsState } from './state.js';

export class Entry extends CoreComponent {
    static is = 'entry';

    static get styles() {
        return [
            ...super.styles,
            this.css`
                :host {
                    display: grid;
                }
            `,
        ]
    }

    static template = this.html`
        <feed-card class="feed-card">
            <a class="thumbnail title-link" href="#"></a>

            <a class="title-link" href="#" slot="title">
                <feed-title class="title-text"></feed-title>
            </a>

            <a class="category-link" href="#category" slot="category">
                <feed-category class="category-text"></feed-category>
            </a>

            <feed-source slot="source">
                <a class="source keep-fresh" href="#source"></a>
                <div class="author"></div>
            </feed-source>

            <feed-date class="date" slot="date"></feed-date>

            <app-actions slot="actions">
                <icon-button class="star" icon="mdi:star"></icon-button>
                <icon-button class="read" icon="mdi:check"></icon-button>
                <a class="external" href="#" target="_blank">
                    <icon-button icon="mdi:open-in-new"></icon-button>
                </a>
            </app-actions>
        </feed-card>
    `;

    #data = {};
    #content = null;

    firstRendered() {
        this.updateEntry();

        this.showThumbnailUnsubscribe = settingsState.showThumbnail.subscribe(
            show => this.$('.thumbnail').hidden = !show
        );

        this.$('.star').addEventListener('click', this.toggleBookmark);
        this.$('.read').addEventListener('click', this.toggleReadStatus);
    }

    disconnectedCallback() {
        this.showThumbnailUnsubscribe();
    }

    toggleBookmark = async () => {
        await appState.toggleBookmark(this.entryId);
        this.starred = !this.starred;
    };

    toggleReadStatus = async () => {
        const status = this.read ? 'unread' : 'read';
        await appState.toggleReadStatus(this.entryId, status);
        this.read = status;
    };

    updateEntry() {
        this.$$('.title-link').forEach(el => el.href = `#/post/${this.entryId}`);
        this.$('.title-text').innerText = this.title;

        this.$('.category-link').href = `#/categories/${this.categoryId}`;
        this.$('.category-text').innerText = this.categoryTitle;

        this.$('.source').href = `#/feeds/${this.feedId}`;
        this.$('.source').innerText = this.feedTitle;
        this.$('.author').innerText = this.author;

        this.$('.date').timestamp = this.publishedAt;

        this.updateStarred();
        this.updateRead();
        this.$('.external').href = settingsState.rewriteUrl(this.url);
        this.$('.external').title = this.title;

        if (this.thumbnail) {
            this.$('.thumbnail').replaceChildren(this.thumbnail);
        }
    }

    updateStarred() {
        this.$('.feed-card').starred = this.starred;
        this.$('.star').icon = this.starred ? 'mdi:star' : 'mdi:star-outline';
        this.toggleAttribute('starred', this.starred);
    }

    updateRead() {
        this.$('.feed-card').read = this.read;
        this.$('.read').icon = this.read ? 'mdi:eye-outline' : 'mdi:check';
        this.toggleAttribute('read', this.read);
    }

    get data() {
        return this.#data;
    }

    set data(value) {
        this.#data = value;
        this.#content = parseContent(value?.content || '');
    }

    get entryId() {
        return this.#data.id;
    }

    get title() {
        return this.#data.title;
    }

    get url() {
        return this.#data.url;
    }

    get starred() {
        return this.#data.starred;
    }

    set starred(value) {
        this.#data.starred = value;
        this.updateStarred();
    }

    get status() {
        return this.#data.status;
    }

    get read() {
        return this.status === 'read';
    }

    set read(value) {
        this.#data.status = value;
        this.updateRead();
        this.fireEvent('entry-read-status-changed', {
            feedId: this.feedId,
            categoryId: this.categoryId,
            read: this.read,
        });
    }

    get author() {
        return this.#data.author;
    }

    get publishedAt() {
        return this.#data.published_at;
    }

    get feedId() {
        return this.#data.feed.id;
    }

    get feedTitle() {
        return this.#data.feed.title;
    }

    get categoryId() {
        return this.#data.feed.category.id;
    }

    get categoryTitle() {
        return this.#data.feed.category.title;
    }

    get content() {
        return this.#content?.cloneNode(true).childNodes || document.createElement('div');
    }

    get thumbnail() {
        if (!this.#content) return;

        const imgEl = this.#content.cloneNode(true).querySelector('img');
        if (imgEl) imgEl.onerror = () => imgEl.hidden = true;

        return imgEl;
    }
}

Entry.define();