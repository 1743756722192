import { WebComponent, css, html } from '../lib/web-component.js';

export class ToggleButton extends WebComponent {
    static is = 'toggle-button';

    static observedAttributes = ['open'];

    static styles = css`
        :host {
            display: flex;
            flex-flow: row;
            align-items: center;
            gap: 1rem;
        }

        :host([reverse]) {
            flex-flow: row-reverse;
        }

        .Content { display: none; }
        .Trigger-closeIcon { display: none; }
        .Trigger-openIcon { display: block; }

        :host([open]) .Content { display: block; }
        :host([open]) .Trigger-closeIcon { display: block; }
        :host([open]) .Trigger-openIcon { display: none; }
    `;

    static template = html`
        <div class="Trigger">
            <div class="Trigger-openIcon">
                <slot name="open">
                    <icon-button title="Open" icon="mdi:expand-vertical"></icon-button>
                </slot>
            </div>

            <div class="Trigger-closeIcon">
                <slot name="close">
                    <icon-button title="Close" icon="mdi:collapse-vertical"></icon-button>
                </slot>
            </div>
        </div>

        <div class="Content">
            <slot></slot>
        </div>
    `;

    firstRendered() {
        this.open = this.hasAttribute('open');

        this.$('.Trigger').addEventListener('click', () => {
            this.open = !this.open;
        });
    }

    get open() {
        return this.hasAttribute('open');
    }

    set open(value) {
        this.toggleAttribute('open', value);

        if (value) {
            this.querySelector('input[autofocus]')?.focus();
        }
    }

    attributeChangedCallback(_attr, oldValue, newValue) {
        if (newValue === oldValue) return;

        this.open = this.hasAttribute('open');
    }
}

ToggleButton.define();

