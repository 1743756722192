import { parseSettings } from '../lib/parser.js';
import { reactive, computed } from '../lib/reactive.js';
import { config } from '../ui/tokens.js';
import * as api from './api.js';

class AccountState {
    #accounts = reactive([], 'flux-accounts');
    activeToken = reactive(null, 'flux-active-token');

    addAccount(account) {
        for (const existingAccount of this.#accounts.value) {
            if (existingAccount.token === account.token) return;
        }

        this.#accounts.value = [...this.#accounts.value, account];
        this.activeToken.value = account.token;
    }

    updateAccount(token, data) {
        this.#accounts.value = this.#accounts.value.map(account => {
            if (token !== account.token) return account;

            return {
                ...account,
                ...data,
            };
        });
    }

    switchAccount(token) {
        this.activeToken.value = token;
    }

    removeAccount(token) {
        this.#accounts.value = this.#accounts.value.filter(
            account => account.token !== token
        );

        if (this.activeToken.value === token) {
            this.activeToken.value = this.#accounts.value[0]?.token || null;
        }
    }

    get auth() {
        return this.activeAccount.value;
    }

    activeAccount = computed(
        (accounts, token) => {
            for (const acc of accounts) {
                if (acc.token === token) return acc;
            }
        },
        this.#accounts, this.activeToken
    );

    subAccounts = computed(
        (accounts, token) => accounts.filter(account => account.token !== token),
        this.#accounts, this.activeToken
    );
}

export const accountState = new AccountState();

class Settings {
    static get defaultSettings() {
        return {
            theme: 'auto',
            auto_mark_entries_as_read: true,
            hide_read_feeds_in_menu: false,
            bottom_bar: true,
            auto_open_folder: true,
            mark_duplicate_entries: true,
            show_broken_feeds: true,
            show_thumbnail: true,
            tts_voice: null,
            youtube_embed: null,
        };
    }

    static parseSettings(rawSettings={}) {
        const allowedSettings = Object.keys(this.defaultSettings);

        const settings = Object.entries(rawSettings)
            .filter(([key, _]) => allowedSettings.includes(key));

        return {
            ...this.defaultSettings,
            ...Object.fromEntries(settings),
        };
    }

    online = reactive(navigator.onLine);

    settings = computed(
        account => account || { ...this.constructor.defaultSettings },
        accountState.activeAccount
    );

    // TODO: Implement rewrites advanced rules with JSONLogic
    rewrites = computed(
        settings => settings.rewrites || {},
        this.settings
    );

    theme = computed(
        settings => settings.theme,
        this.settings
    );

    themeColor = computed(
        theme => config.theme[theme]?.surface1 || '#272727',
        this.theme
    );

    autoMarkEntriesAsRead = computed(
        settings => settings.auto_mark_entries_as_read,
        this.settings
    );

    hideReadFeedsInMenu = computed(
        settings => settings.hide_read_feeds_in_menu,
        this.settings
    );

    bottomBar = computed(
        settings => settings.bottom_bar,
        this.settings
    );

    autoOpenFolder = computed(
        settings => settings.auto_open_folder,
        this.settings
    );

    markDuplicateEntries = computed(
        settings => settings.mark_duplicate_entries,
        this.settings
    );

    showBrokenFeeds = computed(
        settings => settings.show_broken_feeds,
        this.settings
    );

    showThumbnail = computed(
        settings => settings.show_thumbnail,
        this.settings
    );

    ttsVoice = computed(
        settings => settings.tts_voice,
        this.settings
    );

    youtubeEmbed = computed(
        settings => settings.youtube_embed || 'www.youtube-nocookie.com',
        this.settings
    );

    rewriteUrl(url) {
        const { host } = new URL(url);

        const rule = this.rewrites.value[host] || null;

        if (rule === null) return url;

        return url.replace(host, rule);
    }
}

export const settingsState = new Settings();

class FeedState {
    #categories = reactive([]);
    #feeds = reactive([]);
    #counters = reactive({});

    updateCategories(categories=[]) {
        this.#categories.value = categories;
    }

    updateFeeds(feeds=[]) {
        this.#feeds.value = feeds;
    }

    updateCounters(counters={}) {
        this.#counters.value = counters;
    }

    reset() {
        this.updateCategories();
        this.updateFeeds();
        this.updateCounters();
    }
    
    getCategoryById(id) {
        for (const category of this.#categories.value) {
            if (category.id == id) return category;
        }
        return '';
    }

    getFeedById(id) {
        for (const feed of this.#feeds.value) {
            if (feed.id == id) return feed;
        }
        return '';
    }

    categories = computed(
        categories => categories.map(
            category => ({ value: category.id, label: category.title })
        ),
        this.#categories
    );

    categoryFeeds = computed(
        (allCategories, allFeeds, counters, hideReadFeeds) => {
            const categories = [];

            for (const category of allCategories) {
                const feeds = [];
                let categoryUnread = 0;

                for (const feed of allFeeds) {
                    if (feed.category.id !== category.id) continue;

                    const unread = counters[feed.id] || 0;

                    if (hideReadFeeds && unread === 0) continue;

                    feeds.push({ ...feed, unread });
                    categoryUnread += unread;
                }

                if (hideReadFeeds && categoryUnread === 0) continue;

                categories.push({
                    ...category,
                    feeds,
                    unread: categoryUnread,
                });
            }
            return categories;
        },
        this.#categories, this.#feeds, this.#counters, settingsState.hideReadFeedsInMenu
    );

    globalUnread = computed(
        unreads => Object.values(unreads).reduce((total, i) => total + i, 0),
        this.#counters
    );

    brokenFeeds = computed(
        (enabled, feeds) => enabled ? feeds.filter(
            feed => feed.parsing_error_count
        ) : [],
        settingsState.showBrokenFeeds, this.#feeds
    );

    brokenFeedsCount = computed(
        feeds => feeds.length,
        this.brokenFeeds
    );

    // TODO: Use rewrite and jsonLogic
    extractDiscoverableUrls(url) {
        return [ url ];
    }

    async discoverFeeds(discoverUrl, data) {
        const urls = this.extractDiscoverableUrls(discoverUrl);

        return await Promise.all(
            urls.map(
                url => api.discoverFeeds(accountState.auth, url, data)
                    .catch((e) => [{
                        title: 'Error',
                        url: discoverUrl,
                        error: e.error_message || 'Unknown error',
                    }])
            )
        ).then(feeds => feeds.flat());
    }

    async addFeed(url, data) {
        try {
            const res = await api.addFeed(accountState.auth, url, data);

            location.hash = `/feeds/${res.feed_id}`;

            return res;

        } catch(e) {
            throw e.error_message || 'Unknown error';
        }
    }
}

export const feedState = new FeedState();

class ListState {
    search = reactive('');
    includeRead = reactive(false);
    onlyStarred = reactive(false);

    updateSearch(value) {
        this.search.value = value;
    }

    updateIncludeRead(value) {
        this.includeRead.value = value;
    }

    updateOnlyStarred(value) {
        this.onlyStarred.value = value;
    }

    reset() {
        this.updateSearch('');
        this.updateIncludeRead(false);
        this.updateOnlyStarred(false);
    }

    filtersApplied = computed(
        (includeRead, onlyStarred) => !!includeRead || !!onlyStarred,
        this.includeRead, this.onlyStarred
    );

    filters = computed(
        (search, includeRead, onlyStarred) => ({
            search,
            status: includeRead ? 'read' : '',
            starred: onlyStarred ? true : '',
        }),
        this.search, this.includeRead, this.onlyStarred
    );
}

export const listState = new ListState();

class EntryState {
    #entry = reactive({});
    #lastUsedListFilters = reactive([], 'flux-last-used-list-filters', sessionStorage);
    prevEntry = reactive(null);
    nextEntry = reactive(null);

    updateEntry(data) {
        this.#entry.value = data;
    }

    updateLastUsedListFilters(data) {
        this.#lastUsedListFilters.value = data;
    }

    updateAdiacentEntries({ next=null, prev=null }) {
        this.prevEntry.value = prev;
        this.nextEntry.value = next;
    }

    title = computed(
        ({ title }) => title,
        this.#entry
    );

    #excludedFilters = ['direction', 'limit', 'offset'];

    entryFilters = computed(
        filters => ([
            ...filters.filter(
                ([key, _]) => !this.#excludedFilters.includes(key)
            ),
            ...Object.entries({
                status: 'read',
                limit: 1,
                offset: 0,
            }),
        ]),
        this.#lastUsedListFilters
    );
}

export const entryState = new EntryState();

class ViewState {
    static VIEW_TYPE = {
        LIST: 'LIST',
        POST: 'POST',
    };

    #route = reactive('', 'flux-route', sessionStorage);

    updateRoute(route) {
        this.#route.value = route;
    }

    #parts = computed(
        path => path.split('/').filter(i => i),
        this.#route
    );

    #routeFilters = computed(
        ([ route, id, ..._]) => {
            switch (route) {
                case 'starred':
                    return { starred: true };

                case 'history':
                    return { status: 'read', direction: 'desc' };

                case 'categories':
                    return { category_id: id, status: 'unread' };

                case 'feeds':
                    return { feed_id: id, status: 'unread' };

                case 'post':
                    return { entry_id: id };

                default:
                    return { status: 'unread' };
            }
        },
        this.#parts
    );

    routeSelector = computed(
        ([ route, id, ..._ ]) => `[data-route="${route || 'unread'}"]${id ? `[data-id="${id}"]` : ''}`,
        this.#parts
    );

    currentFeedId = computed(
        filters => filters?.feed_id,
        this.#routeFilters
    );

    currentEntryId = computed(
        filters => filters?.entry_id,
        this.#routeFilters
    );

    view = computed(
        ([ route, ..._ ]) => route || 'unread',
        this.#parts
    );

    viewType = computed(
        (view, token) => {
            if (token) {
                return view === 'post' ? 'POST' : 'LIST';
            }
            return 'SPLASH';
        },
        this.view, accountState.activeToken
    );

    currentViewTitle = computed(
        ([ route, id, ..._], entryTitle, token) => {
            if (!token) return 'Welcome to Pocketflux';

            switch (route) {
                case 'starred':
                    return 'Starred';

                case 'history':
                    return 'History';

                case 'categories':
                    return feedState.getCategoryById(id)?.title;

                case 'feeds':
                    return feedState.getFeedById(id)?.title;

                case 'post':
                    return entryTitle;

                default:
                    return 'Unread';
            }
        },
        this.#parts, entryState.title, accountState.activeToken, feedState.categoryFeeds
    );

    currentViewSourceUrl = computed(
        ([ route, id, ..._ ]) => {
            switch (route) {
                case 'feeds':
                    return feedState.getFeedById(id)?.site_url;

                default:
                    return '';
            }
        },
        this.#parts, feedState.categoryFeeds
    );

    filters = computed(
        (account={}, routeFilters={}, listFilters={}) => ([
            ...Object.entries({
                direction: account.direction,
                sorting_order: account.sorting_order,
            }),
            ...Object.entries(routeFilters),
            ...Object.entries(listFilters),
        ].filter(([ _, value ]) => value)),
        accountState.activeAccount, this.#routeFilters, listState.filters
    );
}

export const viewState = new ViewState();

class PWAState {
    showPrompt = reactive(false);
}

export const pwaState = new PWAState();

class AppState {
    async login(data) {
        try {
            const res = await api.login(data);

            const options = this.#getOptions(res);
            const settings = this.#parseSettings(res.stylesheet);

            const result = {
                ...data,
                ...options,
                ...settings,
            };

            accountState.addAccount(result);
            location.hash = '';

            return result;

        } catch(e) {
            throw e.error_message || 'Unknown error';
        }
    }

    async refreshSettings() {
        if (!accountState.activeToken) return;

        const res = await api.login(accountState.auth);
        const options = this.#getOptions(res);
        const settings = this.#parseSettings(res.stylesheet);

        accountState.updateAccount(
            accountState.activeToken.value,
            {
                ...options,
                ...settings,
            }
        );
    }

    #getOptions(res) {
        return {
            id: res.id,
            username: res.username,
            direction: res.entry_sorting_direction,
            sorting_order: res.entry_sorting_order,
            entries_per_page: res.entries_per_page,
        };
    }

    #parseSettings(settingsString) {
            let settings = {};
            let rewrites = {};

            try {
                [ settings, rewrites ] = parseSettings(settingsString);

            } catch (e) {
                console.error(e);
                return {};
            }

            return {
                ...Settings.parseSettings(settings),
                rewrites,
            };
    }

    switchAccount(token) {
        accountState.switchAccount(token);
        location.hash = '';
    }

    removeAccount(token) {
        accountState.removeAccount(token);
        location.hash = '';
    }

    logout() {
        accountState.removeAccount(accountState.activeToken.value);
        feedState.reset();
        location.hash = '';
    }

    updateSettings(settings={}) {
        accountState.updateAccount(
            accountState.activeToken.value,
            settings
        );
    }

    async fetchFeeds() {
        const [ categories, feeds, counters ] = await Promise.all([
            api.getCategories(accountState.auth),
            api.getFeeds(accountState.auth),
            api.getCounters(accountState.auth),
        ]);
        feedState.updateCategories(categories);
        feedState.updateFeeds(feeds);
        feedState.updateCounters(counters.unreads);
    }

    async refreshFeed(feedId) {
        return await api.refreshFeed(accountState.auth, feedId);
    }

    handleRoute(route) {
        viewState.updateRoute(route);
    }

    async fetchEntries(page=0) {
        if (viewState.viewType.value === 'POST') return {};

        const filters = [
            ...viewState.filters.value,
            ...Object.entries({
                limit: accountState.auth.entries_per_page,
                offset: accountState.auth.entries_per_page * page,
            }),
        ];

        if (viewState.viewType.value === 'LIST') {
            entryState.updateLastUsedListFilters(viewState.filters.value);
        }

        return await api.getEntries(accountState.auth, filters);
    }

    async toggleBookmark(id) {
        return await api.toggleBookmark(accountState.auth, id);
    }

    async toggleReadStatus(id, status) {
        return await api.toggleReadStatus(accountState.auth, id, status);
    }

    async fetchEntry(id) {
        if (!id) return;

        const entry = await api.getEntry(accountState.auth, id);

        entryState.updateEntry(entry);

        this.fetchEntryAdiacent(entry.id);

        return entry;
    }

    async fetchEntryOriginalContent(id) {
        return await api.getEntryOriginalContent(accountState.auth, id);
    }

    async fetchEntryAdiacent(id) {
        const prevEntryFilters = [
            ...entryState.entryFilters.value,
            ...Object.entries({
                direction: 'asc',
                after_entry_id: id,
            }),
        ];

        const nextEntryFilters = [
            ...entryState.entryFilters.value,
            ...Object.entries({
                direction: 'desc',
                before_entry_id: id,
            }),
        ];
        
        const [ prevResponse, nextResponse ] = await Promise.all([
            api.getEntries(accountState.auth, prevEntryFilters),
            api.getEntries(accountState.auth, nextEntryFilters),
        ]);

        const prevEntries = prevResponse?.entries || [];
        const nextEntries = nextResponse?.entries || [];

        const adiacentEntries = {
            prev: prevEntries[0] || null,
            next: nextEntries[0] || null,
        };

        entryState.updateAdiacentEntries(adiacentEntries);

        return adiacentEntries;
    }
}

export const appState = new AppState();
